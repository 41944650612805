export const validationRules = {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        multi_select_required: (value) => value.length > 0 || "Required.",
        detailsRule: (v) =>
          !v || v?.toString().length <= 10 || this.$t("Too much"),
        max_length(max) {
          return (v) => (v && v.length <= max) || `Max ${max} characters`;
        },
        min_length(min) {
          return (v) => (v && v.length >= min) || `Min ${min} characters`;
        },
        min_year() {
          return (v) => (v && v >= 1900) || `Min year should be from 1900`;
        },
        max_year() {
          return (v) =>
            (v && v <= new Date().getFullYear()) ||
            `Max year should be ${new Date().getFullYear()}`;
        },
        min_8: (v) => !v || v.length >= 8 || "Min 8 characters",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
        phone: (v) =>
          !v ||
          /^(\+?(\d{1,3}))[-./ ]?[1-9]{2}([-./ ]?[0-9]{2}){3}$/.test(v) ||
          /^(\+?(\d{1,3}))[-./ ]?[5-9]([-./ ]?[0-9]{2}){4}$/.test(v) ||
          "Phone must be valid",
        quantity: (v) => !v || v > 0 || "Not valid",
        max_quantity(max) {
          return (v) => v <= max || "max " + max;
        },
        min_quantity(min) {
          return (v) => v >= min || "min " + min;
        },
        // minValue(min) {
        //   return (v) => {
        //     return v > min || "Should be greater than " + min;
        //   };
        // },
        // maxValue(max) {
        //   return (v) => v < max || "Should be less than " + max;
        // },
        lessThan(max) {
          return (v) =>
            v && max
              ? parseFloat(v) <= parseFloat(max) || "Should be less than " + max
              : true;
        },
        greaterThan(min) {
          return (v) =>
            v && min
              ? parseFloat(v) >= parseFloat(min) ||
                "Should be greater than " + min
              : true;
        },
        startFrom(val) {
          return (v) => (v && v[0] == val) || "should be start from " + val;
        },
        min_price(min) {
          return (v) => v >= min || "min price " + min;
        },
        superior_to(min) {
          return (v) => v >= min || "should be superior to " + min;
        },
        same_as_password(password) {
          return (v) => v == password || "confirmation password does not match";
        },
        not_empty: (v) => !!(v && v.length) || this.$t("not_empty_rule"),
        alpha_num: (v) =>
          !!(v && v.length && /^[a-zA-Z0-9-//]+$/.test(v)) ||
          "only alphanumeric allowed",
        reg_num: (v) =>
          v && v.length
            ? /^[a-zA-Z0-9]{2,4}-\d{3,4}$/.test(v) ||
              "Use this format 'xy-123' or 'xyz-1234'."
            : true,
        // !!(v && v.length && /^[a-zA-Z0-9]{2,4}-\d{3,4}$/.test(v)) ||
        // "Use this format 'xy-123' or 'xyz-1234'.",

        number: (v) =>
          !!(v && v.length && /^[0-9-//]+$/.test(v)) || "only numeric allowed",
        //  validation for and not Required

        float(val) {
          return (v) =>
            v && v.length
              ? new RegExp(`^[0-9]*(\\.[0-9]{0,${val}})?$`).test(v) ||
                `only numeric or ${val} decimal allowed`
              : true;
        },
        latlng: (v) =>
          v && v.length
            ? /^[0-9]*(\.[0-9]{0,50})?$/.test(v) || "only decimal allowed"
            : true,
        file_size: (value) =>
          !value ||
          value.size < 2000000 ||
          "File size should be less than 2 MB!",
      },
    };
  },
  methods: {
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    validateDateOfBirth(value) {
      const currentDate = this.getCurrentDate();
      const selectedDate = value;

      const ageLimitDate = new Date();
      ageLimitDate.setFullYear(ageLimitDate.getFullYear() - 18);
      ageLimitDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

      if (!value) {
        return "Date of birth is required";
      } else if (selectedDate > currentDate) {
        return "Date of birth cannot be in the future";
      } else if (this.calculateAge(selectedDate) < 18) {
        return "Customer must be at least 18 years old";
      }

      return true;
    },
    calculateAge(date) {
      const birthDate = new Date(date);
      const currentDate = new Date();
      let age = currentDate.getFullYear() - birthDate.getFullYear();
      const monthDifference = currentDate.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    getStartingYear() {
      return 1900;
    },
    endYearRule(stYR, enYR) {
      const startYear = parseInt(stYR);
      const endYear = parseInt(enYR);
      if (enYR && enYR.length > 4) {
        return `Max year should be ${new Date().getFullYear()}`;
      } else if (!isNaN(startYear) && !isNaN(endYear) && endYear < startYear) {
        return "End year should not be less than start year.";
      }
      return true;
    },
  },
};
